<template>
  <div>
    <v-navigation-drawer
      id="authenticated-navigation-drawer"
      v-model="drawer"
      color="neutral"
      clipped
      disable-resize-watcher
      app
      right
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h6">
              <logo-group
                :logomark="true"
                :logotype="true"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </template>
      <v-list
        v-if="$store.state.isAuthenticated && $store.state.user.verified"
        nav
      >
        <template v-for="(item, index) in navItemsAuthenticated">
          <v-divider
            v-if="item.divider"
            :key="index"
            class="my-2"
          />
          <v-list-item
            v-else-if="item.name === 'company'"
            :key="item.name"
            :to="{ name: item.name, params: { lang: $i18n.locale } }"
            :class="{ activeTab : $route.path.includes(item.name), 'd-none': (adminForCompanies.length < 1) }"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="item.name && item.name !== 'logout'"
            :key="item.key"
            :to="{ name: item.name, params: { lang: $i18n.locale } }"
            :class="{ activeTab : $route.name === item.name }"
            exact
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="item.name === 'logout'"
            :key="item.url"
            @click="onLogout()"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-right
      color="neutral"
    >
      <v-toolbar-title>
        <logo-group
          :logomark="true"
          :logotype="$vuetify.breakpoint.mdAndUp"
        />
      </v-toolbar-title>
      <v-spacer />
      <LanguagePicker
        class="language-picker-nav ml-2 animate__animated animate__fadeInRight animate__delay-500ms"
      />
      <v-btn
        icon
        class="animate__animated animate__fadeIn animate__fadeInRight animate__delay-750ms"
        :to="{ name: 'settings' }"
      >
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
      <v-btn
        v-if="$store.state.isAuthenticated && !$store.state.user.verified"
        color="secondary"
        class="secondary--text animate__animated animate__fadeInRight animate__delay-500ms"
        rounded
        outlined
        depressed
        @click="onLogout"
      >
        {{ $t('Navigation.logout') }}
      </v-btn>
      <ToolbarMenu
        v-if="$store.state.isAuthenticated && $store.state.user.verified"
        :logout="onLogout"
      />
    </v-app-bar>
  </div>
</template>

<script>
import navItemsDrawerAuthenticated from '@/data/navigation/navItemsDrawerAuthenticated.json'
import LogoGroup from '@/common/LogoGroup.vue'
// import SearchInputBox from '@/pages/search/components/SearchInputBox.vue'
import ToolbarMenu from '@/common/layout/components/ToolbarMenu.vue'
import LanguagePicker from '@/common/LanguagePicker.vue'

import { mapState } from 'vuex'
import { LOGOUT } from '@/store/actions.type'

// TODO: Finish adding routes
export default {
  name: 'Toolbar',
  components: {
    LanguagePicker,
    ToolbarMenu,
    LogoGroup
    // SearchInputBox
  },
  props: {
    createAlert: {
      type: Function,
      default: () => {
      }
    }
  },
  data: () => ({
    navItemsAuthenticated: navItemsDrawerAuthenticated,
    drawer: false,
    navItemsAuthenticatedNotVerified: [
      {
        title: 'Navigation.logout',
        name: 'logout'
      },
      {
        title: 'Navigation.search',
        name: 'search'
      },
      {
        title: 'Navigation.about',
        name: 'about'
      },
      {
        title: 'Navigation.consultants',
        name: 'consultants'
      }
    ]
  }),
  computed: {
    ...mapState([
      'isLoading',
      'user',
      'adminForCompanies'
    ])
  },
  methods: {
    onLogout () {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'accounts-login' })
      })
    }
  }
}
</script>
