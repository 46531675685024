<template>
  <div>
    <AuthenticatedToolbar
      :logout="logout"
    />
    <v-main>
      <v-responsive>
        <router-view
          :logout="logout"
          :create-alert="createAlert"
        />
      </v-responsive>
    </v-main>
    <CustomAlert
      v-show="alertVisible"
      :message="alert.message"
      :type="alert.type"
      :icon="alert.icon"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthenticatedToolbar from '@/common/layout/AuthenticatedToolbar.vue'
import CustomAlert from '@/common/CustomAlert.vue'
import { LOGOUT } from '@/store/actions.type'

export default {
  name: 'InnerView',
  components: {
    AuthenticatedToolbar,
    CustomAlert
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timer)
    next()
  },
  data () {
    return {
      alert: {
        message: '',
        icon: false,
        type: 'error'
      },
      alertVisible: false
    }
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },
  watch: {
    '$route' () {
      clearInterval(this.timer)
    }
  },
  mounted () {
    this.$root.$on('errorMessage', err => {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        if (err.status === 0 || err.status === 500) {
          this.createErrorAlert(this.$t('Validation.something_went_wrong'))
        } else {
          if (!err.data.errorMessage) {
            this.createErrorAlert(err.data)
          } else {
            this.createErrorAlert(err.data.errorMessage)
          }
        }
      } else if (err.request) {
        // client never received a response, or request never left
        this.createErrorAlert(err.request)
      } else {
        // anything else
        console.error('err')
        this.createErrorAlert(err.message)
      }
    })
    this.$root.$on('successMessage', message => {
      this.createSuccessAlert(message)
    })
  },
  beforeDestroy () {
    this.$root.$off('errorMessage')
  },
  methods: {
    createAlert (message, icon) {
      this.alert.message = message
      this.alert.icon = icon
      this.alertVisible = true
      setTimeout(() => {
        this.alertVisible = false
      }, 5000)
    },
    createErrorAlert (message) {
      this.alert.type = 'error'
      this.alert.message = message
      this.alertVisible = true
      setTimeout(() => {
        this.alertVisible = false
      }, 5000)
    },
    createSuccessAlert (message) {
      this.alert.type = 'success'
      this.alert.message = message
      this.alertVisible = true
      setTimeout(() => {
        this.alertVisible = false
      }, 5000)
    },
    logout () {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'accounts-login' })
      })
    }
  }
}
</script>
