<template>
  <v-menu
    v-model="navMenu"
    attach
    offset-y
    left
    class="inner-navigation"
    content-class="top-nav-menu rounded-xl my-2"
  >
    <template #activator="{ on }">
      <v-btn
        id="menu-dropdown"
        icon
        v-on="on"
      >
        <v-avatar size="36px">
          <v-img
            :src="pictureUrl"
            aspect-ratio="1"
          />
        </v-avatar>
      </v-btn>
    </template>
    <v-card
      color="neutral"
    >
      <v-list
        rounded-xl
        color="neutral"
        class="avatar-box"
      >
        <v-list-item
          ripple
          @click="$router.push({ name: 'edit-profile' })"
        >
          <v-list-item-avatar>
            <v-img
              :src="pictureUrl"
              aspect-ratio="1"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
            <v-list-item-subtitle
              id="view-profile"
              class="view-profile"
              v-text="$t('Pages.Search.view_profile')"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list
        rounded-xl
        color="neutral"
      >
        <template v-for="item in menuItems">
          <v-list-item
            v-if="item.name === 'logout'"
            :id="item.id"
            :key="item.id"
            ripple
            @click="logout()"
          >
            <v-list-item-avatar v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content
              v-if="item.title"
            >
              {{ $t(item.title) }}
            </v-list-item-content>
            <v-list-item-action v-if="item.chip">
              <v-chip
                small
                color="secondary"
              >
                {{ item.chip }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-else-if="item.name === 'feedback'"
            :id="item.id"
            :key="item.id"
            ripple
            @click.stop="$root.$emit('openDialog')"
          >
            <v-list-item-avatar v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content
              v-if="item.title"
            >
              {{ $t(item.title) }}
            </v-list-item-content>
            <v-list-item-action v-if="item.chip">
              <v-chip
                small
                color="secondary"
              >
                {{ item.chip }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-else-if="item.id === 'faq'"
            :id="item.id"
            :key="item.id"
            :href="item.href"
            target="_blank"
            rel="noopener"
            ripple
          >
            <v-list-item-avatar v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content
              v-if="item.title"
            >
              {{ $t(item.title) }}
            </v-list-item-content>
            <v-list-item-action v-if="item.chip">
              <v-chip
                small
                color="secondary"
              >
                {{ item.chip }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-else-if="!item.href"
            :id="item.id"
            :key="item.id"
            :to="{ name: item.name, query: item.query }"
            :class="{ activeTab: $route.name === item.name }"
            ripple
            exact
          >
            <v-list-item-avatar v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content
              v-if="item.title"
            >
              {{ $t(item.title) }}
            </v-list-item-content>
            <v-list-item-action v-if="item.chip">
              <v-chip
                small
                color="secondary"
              >
                {{ item.chip }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
    <Feedback :create-alert="createAlert" />
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import { LOGOUT } from '@/store/actions.type'
import Feedback from '@/common/layout/components/Feedback.vue'
import toolbarMenuItems from '@/data/navigation/toolbarMenuItems.json'
import toolbarMenuItemsNoBankid from '@/data/navigation/toolbarMenuItemsNoBankid.json'
import defaultAvatar from '@/assets/img/defaultAvatar.png'
export default {
  components: {
    Feedback
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    }
  },
  data () {
    return {
      feedbackDialog: false,
      navMenu: false,
      toolbarMenuItems,
      toolbarMenuItemsNoBankid,
      defaultAvatar
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      nin: 'nin',
      pictureUrl: state => state.user.picture.url ? state.user.picture.url : defaultAvatar
    }),
    menuItems: function () {
      if (this.nin) {
        return this.toolbarMenuItems
      } else {
        return this.toolbarMenuItemsNoBankid
      }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'accounts-login' })
      })
    }
  }
}
</script>

<style lang="css" scoped>
.avatar-box {
  cursor: pointer;
}

.view-profile {
  text-decoration: underline;
}
</style>
