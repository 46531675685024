<template>
  <div
    class="language-picker"
  >
    <v-menu
      transition="slide-y-transition"
      offset-y
      bottom
      rounded="xl"
      color="warning"
    >
      <template #activator="{ on }">
        <v-btn
          id="language-picker"
          aria-label="Language Picker"
          icon
          color="secondary"
          v-on="on"
        >
          <v-icon>mdi-translate</v-icon>
        </v-btn>
      </template>
      <v-list
        color="neutral"
        class="warning"
        dense
      >
        <v-list-item-group
          v-model="activeLanguage"
          active-class="warning--text"
          color="neutral"
        >
          <v-list-item
            :input-value="$i18n.locale === 'sv'"
            @click="translateI18n('sv')"
          >
            <v-list-item-content>
              <v-list-item-title>
                Svenska
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :input-value="$i18n.locale === 'en'"
            @click="translateI18n('en')"
          >
            <v-list-item-content>
              <v-list-item-title>
                English
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import languages from '@/data/i18n/languages.json'
export default {
  name: 'LanguagePicker',
  data: () => ({
    languages
  }),
  computed: {
    currentLanguage () {
      return this.languages.find(l => l.locale === this.$i18n.locale)
    },
    activeLanguage: {
      get () {
        return this.$i18n.locale === 'sv' ? 0 : 1
      },
      set () {
        return this.$i18n.locale === 'sv' ? 0 : 1
      }
    }
  },
  methods: {
    translateI18n (locale) {
      if (locale !== this.$i18n.locale) {
        this.$i18n.locale = locale
        this.$vuetify.lang.current = locale
        this.$router.replace({ params: { lang: locale } })
      }
    }
  }
}
</script>
