<template>
  <v-dialog
    v-model="dialog"
    content-class="rounded-xl"
  >
    <v-card class="neutral rounded-xl">
      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span v-text="$t('Navigation.send_feedback')" />
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="sendFeedback"
      >
        <v-card-text>
          <v-textarea
            v-model="feedbackInput"
            :rules="[importedRules.required, importedRules.only_spaces]"
            outlined
            color="accent"
            :label="$t('Navigation.describe_issue')"
            required
          />
          <small>
            {{ $t('Navigation.feedback_1') }}
            <router-link
              id="privacy-link"
              :to="{ name: 'privacy-policy' }"
              target="_blank"
              rel="noreferrer"
            >
              {{ $t('Footer.privacy') }}
            </router-link>{{ $t('Navigation.feedback_2') }}
            <router-link
              id="terms-link"
              :to="{ name: 'tos-users' }"
              target="_blank"
              rel="noreferrer"
            >
              {{ $t('Footer.terms') }}
            </router-link>
          </small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            rounded
            @click.native="clearFeedbackForm()"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="save-btn"
            :loading="loading"
            :disabled="!valid || loading"
            light
            rounded
            depressed
            type="submit"
            v-text="$t('Common.send')"
          />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { rules } from '@/config/validation-rules.js'

export default {
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    }
  },
  data () {
    return {
      dialog: false,
      valid: false,
      loading: false,
      importedRules: rules,
      feedbackInput: ''
    }
  },
  mounted () {
    this.$root.$on('openDialog', () => {
      this.dialog = !this.dialog
    })
  },
  beforeDestroy () {
    this.$root.$off('openDialog')
  },
  methods: {
    clearFeedbackForm () {
      this.$refs.form.reset()
      this.dialog = false
    },
    sendFeedback () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$http.post('feedback', { feedback: this.feedbackInput }).then(
          () => {
            this.createAlert(
              'Feedback successfully sent! Thank you for your help',
              'check_circle',
              'success'
            )
            this.loading = false
            this.dialog = false
            this.clearFeedbackForm()
          },
          err => {
            console.error(err)
            this.$root.$emit('errorMessage', err.response)
            this.loading = false
          }
        )
      }
    }
  }
}
</script>
